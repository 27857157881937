html,
body {
  height: 100%;
}

body * {
  box-sizing: border-box;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menuButton {
  margin-right: inherit;
}

.title {
  text-align: center;
  flex-grow: 1;
}

.title a {
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
}

.accountButton {
  float: right;
}

.username {
  float: left;
  padding: 10px;
  font-style: italic;
}

.app-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.GridPage {
   overflow: hidden !important;
}
.GridPage .heightWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}